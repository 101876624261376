import React from 'react';
import classNames from 'classnames';
import { Nav } from 'react-bootstrap';

import NotFoundImage from '@uq-assets/not-found.svg';
import { UqLayoutV2, UqPage, UqContainerV2, UqAppBarV2, UqNavbarV2, UqHeroV2 } from '@uq-components';

import * as styles from './404.module.scss';

export default function NotFoundPage() {
  return (
    <UqPage>
      <UqLayoutV2>
        <UqAppBarV2>
          <UqNavbarV2 />
        </UqAppBarV2>

        <UqContainerV2>
          <UqHeroV2>
            <article className={styles.root}>
              <section className={styles.content}>
                <NotFoundImage className={styles.image} />
                <div className={styles.message}>
                  <h4>We can&apos;t seem to find that page.</h4>
                  <Nav.Link
                    className={styles.homeLink}
                    href="/"
                  >
                    <span className={classNames('body-1', styles.homeLink)}>
                      Go to unitQ Home
                    </span>
                  </Nav.Link>
                </div>
              </section>
            </article>
          </UqHeroV2>
        </UqContainerV2>
      </UqLayoutV2>
    </UqPage>
  );
}
